<template>
  <div class="inner-market">
    <div
      class="intro cvr-bg"
      style="background-image: url('/img/dummy/market/1.jpg')"
    >
      <div class="wrapper rowflex">
        <div class="caption">
          <h2>{{ $t("marketMyProperties.section1.title") }}</h2>
          <div class="format-text">
            <p>
              {{ $t("marketMyProperties.section1.body") }}
            </p>
          </div>
          <!-- end of format etxt -->
        </div>
        <!-- end of caption -->

        <div class="submis">
          <div class="layer">
            <form @submit.prevent="submitForm">
              <fieldset>
                <label>
                  {{ $t("marketMyProperties.desiredTransactions") }}<sup>*</sup>
                  <small>
                    ({{ $t("marketMyProperties.chooseTransactions") }})
                  </small>
                </label>
                <div class="field-check">
                  <label>
                    <input
                      type="checkbox"
                      name="transactionType"
                      :value="1"
                      v-model="form.transactionType"
                    />
                    <span>
                      {{ $t("home.section1.Tab1") }}
                    </span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="transactionType"
                      :value="2"
                      v-model="form.transactionType"
                    />
                    <span>
                      {{ $t("home.section1.Tab2") }}
                    </span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="transactionType"
                      :value="3"
                      v-model="form.transactionType"
                    />
                    <span>
                      {{ $t("home.section1.Tab3") }}
                    </span>
                  </label>
                </div>
                <!-- end of field check -->
              </fieldset>

              <div class="gap">
                <fieldset>
                  <label>
                    {{ $t("marketMyProperties.propertyType") }}
                    <sup>*</sup>
                  </label>
                  <div class="field-select">
                    <select
                      required
                      name="propertyType"
                      v-model="form.propertyType"
                    >
                      <option
                        v-for="(property, index) in propertyType"
                        :key="index"
                        :value="property.id"
                      >
                        {{ property.name }}
                      </option>
                    </select>
                    <span>This field is required.</span>
                  </div>
                  <!-- end of select -->
                </fieldset>
                <fieldset>
                  <label>
                    {{ $t("marketMyProperties.region") }}
                    <sup>*</sup>
                  </label>
                  <div class="field-select">
                    <select
                      required
                      name="region"
                      v-model="form.region"
                      @change="getCities"
                    >
                      <option
                        v-for="(region, index) in regions"
                        :key="index"
                        :value="region"
                      >
                        {{ region.name }}
                      </option>
                    </select>
                    <span>This field is required.</span>
                  </div>
                  <!-- end of select -->
                </fieldset>
              </div>

              <!-- end of gap -->
              <div class="gap">
                <fieldset>
                  <label>
                    {{ $t("marketMyProperties.city") }} <sup>*</sup>
                  </label>
                  <div class="field-select">
                    <select required name="city" v-model="form.city">
                      <option
                        v-for="(city, index) in cities"
                        :key="index"
                        :value="city.city"
                      >
                        {{ city.city }}
                      </option>
                    </select>
                    <span>This field is required.</span>
                  </div>
                  <!-- end of select -->
                </fieldset>
                <fieldset>
                  <label>
                    {{ $t("marketMyProperties.postalCode") }}
                    <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    v-model="form.postcode"
                    placeholder="This field is required."
                    required
                  />
                </fieldset>
              </div>
              <!-- end of gap -->

              <fieldset>
                <label>
                  {{ $t("marketMyProperties.address") }} <sup>*</sup>
                </label>
                <input
                  type="text"
                  v-model="form.address"
                  placeholder="This field is required."
                  required
                />
              </fieldset>

              <div class="gap">
                <fieldset>
                  <label
                    v-html="$t('marketMyProperties.desiredRental')"
                  ></label>
                  <div class="field-price">
                    <b>Rp</b>
                    <input
                      type="text"
                      v-model="form.rentalPrice"
                      @keyup="formatRupiah('rentalPrice')"
                    />
                    <b
                      type="button"
                      style="left: auto; right: 0px"
                      @click="showRentalPriceType = true"
                    >
                      / {{ form.rentalType | capitalize }}
                    </b>
                  </div>
                  <div class="field-select" v-show="showRentalPriceType">
                    <select
                      v-model="form.rentalType"
                      @change="showRentalPriceType = false"
                    >
                      <option value="month">
                        / {{ $t("marketMyProperties.month") }}
                      </option>
                      <option value="year">
                        / {{ $t("marketMyProperties.year") }}
                      </option>
                    </select>
                  </div>
                  <!-- end of field price -->
                </fieldset>
                <fieldset>
                  <label v-html="$t('marketMyProperties.desiredSales')"></label>
                  <div class="field-price">
                    <b>Rp</b>
                    <input
                      type="text"
                      v-model="form.salePrice"
                      @keyup="formatRupiah('salePrice')"
                    />
                  </div>
                  <!-- end of field price -->
                </fieldset>
              </div>
              <!-- end of gap -->

              <div class="gap">
                <fieldset>
                  <label>{{ $t("marketMyProperties.landSize") }}</label>
                  <div class="field-price toright">
                    <b> m<span class="meter-persegi">2</span> </b>
                    <input
                      type="number"
                      min="0"
                      step="1"
                      v-model="form.landSize"
                    />
                  </div>
                  <!-- end of field price -->
                </fieldset>
                <fieldset>
                  <label>{{ $t("marketMyProperties.buildingSize") }}</label>
                  <div class="field-price toright">
                    <b> m<span class="meter-persegi">2</span> </b>
                    <input
                      type="number"
                      min="0"
                      step="1"
                      v-model="form.buildingSize"
                    />
                  </div>
                  <!-- end of field price -->
                </fieldset>
              </div>
              <!-- end of gap -->

              <div class="gap">
                <fieldset>
                  <label>{{ $t("marketMyProperties.numBedroom") }}</label>
                  <div class="field-select">
                    <select v-model="form.totalBedRoom">
                      <option value=""></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4+">4+</option>
                    </select>
                  </div>
                  <!-- end of select -->
                </fieldset>
                <fieldset>
                  <label>{{ $t("marketMyProperties.numBathroom") }}</label>
                  <div class="field-select">
                    <select v-model="form.totalBathRoom">
                      <option value=""></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4+">4+</option>
                    </select>
                  </div>
                  <!-- end of select -->
                </fieldset>
              </div>
              <!-- end of gap -->

              <div class="gap">
                <fieldset>
                  <label>{{ $t("marketMyProperties.numCarLot") }}</label>
                  <div class="field-select">
                    <select v-model="form.totalCarLot">
                      <option value=""></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4+">4+</option>
                    </select>
                  </div>
                  <!-- end of select -->
                </fieldset>
                <fieldset>
                  <label>{{ $t("marketMyProperties.waterSource") }}</label>
                  <input type="text" v-model="form.waterSource" />
                </fieldset>
              </div>
              <!-- end of gap -->

              <div class="gap">
                <fieldset>
                  <label>{{ $t("marketMyProperties.electricSource") }}</label>
                  <input type="text" v-model="form.electricSource" />
                </fieldset>
                <fieldset>
                  <label>{{ $t("marketMyProperties.wattage") }}</label>
                  <div class="field-price toright">
                    <b>VA</b>
                    <input
                      type="number"
                      min="0"
                      step="1"
                      v-model="form.wattage"
                    />
                  </div>
                  <!-- end of field price -->
                </fieldset>
              </div>
              <!-- end of gap -->

              <div class="gap">
                <fieldset>
                  <label>Maintenance Fees</label>
                  <div class="field-price">
                    <input
                      type="text"
                      @keyup="formatRupiah('maintenanceFee')"
                      v-model="form.maintenanceFee"
                    />
                    <b
                      type="button"
                      style="left: auto; right: 0px"
                      @click="showMaintenanceFeeType = true"
                    >
                      / {{ form.maintananceFeeType | capitalize }}
                    </b>
                  </div>
                  <div class="field-select" v-show="showMaintenanceFeeType">
                    <select
                      v-model="form.maintananceFeeType"
                      @change="showMaintenanceFeeType = false"
                    >
                      <option value="month">
                        / {{ $t("marketMyProperties.month") }}
                      </option>
                      <option value="year">
                        / {{ $t("marketMyProperties.year") }}
                      </option>
                    </select>
                  </div>
                </fieldset>
                <fieldset>
                  <label>Neighbourhood Fees</label>
                  <div class="field-price">
                    <input
                      type="text"
                      @keyup="formatRupiah('neighborhoodFee')"
                      v-model="form.neighborhoodFee"
                    />
                    <b
                      type="button"
                      style="left: auto; right: 0px"
                      @click="showNeighbourhoodFeeType = true"
                    >
                      / {{ form.neighbourhoodFeeType | capitalize }}
                    </b>
                  </div>
                  <div class="field-select" v-show="showNeighbourhoodFeeType">
                    <select
                      v-model="form.neighbourhoodFeeType"
                      @change="showNeighbourhoodFeeType = false"
                    >
                      <option value="month">
                        / {{ $t("marketMyProperties.month") }}
                      </option>
                      <option value="year">
                        / {{ $t("marketMyProperties.year") }}
                      </option>
                    </select>
                  </div>
                </fieldset>
              </div>
              <!-- end of gap -->

              <fieldset>
                <label>
                  {{ $t("marketMyProperties.description") }}
                </label>
                <textarea v-model="form.description"></textarea>
              </fieldset>
              <div class="centered">
                <template v-if="$store.state.userdata">
                  <button type="submit" class="button">
                    {{ $t("marketMyProperties.contactLO") }}
                  </button>
                </template>
                <template v-else>
                  <button
                    type="button"
                    class="button"
                    data-src="#login-register"
                    data-fancybox
                  >
                    {{ $t("marketMyProperties.contactLO") }}
                  </button>
                </template>
              </div>
            </form>
          </div>
          <!-- end of layer -->
        </div>
        <!-- end of submis -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of intro -->

    <div class="reason">
      <div class="wrapper">
        <div class="caption">
          <h2>{{ $t("marketMyProperties.section2.title") }}</h2>
          <div
            class="format-text"
            v-html="$t('marketMyProperties.section2.body')"
          ></div>
        </div>
      </div>
    </div>
    <!-- end of reason -->

    <div class="emul">
      <div class="wrapper">
        <div class="copy">
          <h2>
            <span>{{ $t("marketMyProperties.section3.title") }}</span>
          </h2>
        </div>
        <!-- end of copy -->
        <div class="list">
          <div class="item">
            <b>1</b>
            <div class="wrap">
              <figure>
                <img src="/img/dummy/market/2.png" width="110" />
              </figure>
              <div class="format-text">
                <p>
                  {{ $t("marketMyProperties.section3.one") }}
                </p>
              </div>
              <!-- end of format text -->
            </div>
            <!--end of wrap -->
          </div>
          <!-- end of item -->
          <div class="item">
            <b>2</b>
            <div class="wrap">
              <figure>
                <img src="/img/dummy/market/3.png" width="110" />
              </figure>
              <div class="format-text">
                <p>
                  {{ $t("marketMyProperties.section3.two") }}
                </p>
              </div>
              <!-- end of format text -->
            </div>
            <!--end of wrap -->
          </div>
          <!-- end of item -->
          <div class="item">
            <b>3</b>
            <div class="wrap">
              <figure>
                <img src="/img/dummy/market/4.png" width="110" />
              </figure>
              <div class="format-text">
                <p>
                  {{ $t("marketMyProperties.section3.three") }}
                </p>
              </div>
              <!-- end of format text -->
            </div>
            <!--end of wrap -->
          </div>
          <!-- end of item -->
          <div class="item">
            <b>4</b>
            <div class="wrap">
              <figure>
                <img src="/img/dummy/market/5.png" width="110" />
              </figure>
              <div class="format-text">
                <p>
                  {{ $t("marketMyProperties.section3.four") }}
                </p>
              </div>
              <!-- end of format text -->
            </div>
            <!--end of wrap -->
          </div>
          <!-- end of item -->
          <div class="item">
            <b>5</b>
            <div class="wrap">
              <figure>
                <img src="/img/dummy/market/6.png" width="110" />
              </figure>
              <div class="format-text">
                <p>
                  {{ $t("marketMyProperties.section3.five") }}
                </p>
              </div>
              <!-- end of format text -->
            </div>
            <!--end of wrap -->
          </div>
          <!-- end of item -->
          <div class="item">
            <b>6</b>
            <div class="wrap">
              <figure>
                <img src="/img/dummy/market/7.png" width="110" />
              </figure>
              <div class="format-text">
                <p>
                  {{ $t("marketMyProperties.section3.six") }}
                </p>
              </div>
              <!-- end of format text -->
            </div>
            <!--end of wrap -->
          </div>
          <!-- end of item -->
          <div class="item">
            <b>7</b>
            <div class="wrap">
              <figure>
                <img src="/img/dummy/market/8.png" width="110" />
              </figure>
              <div class="format-text">
                <p>
                  {{ $t("marketMyProperties.section3.seven") }}
                </p>
              </div>
              <!-- end of format text -->
            </div>
            <!--end of wrap -->
          </div>
          <!-- end of item -->
          <div class="item">
            <b>8</b>
            <div class="wrap">
              <figure>
                <img src="/img/dummy/market/9.png" width="110" />
              </figure>
              <div class="format-text">
                <p>
                  {{ $t("marketMyProperties.section3.eight") }}
                </p>
              </div>
              <!-- end of format text -->
            </div>
            <!--end of wrap -->
          </div>
          <!-- end of item -->
        </div>
        <!-- end of list -->
      </div>
    </div>
  </div>
  <!-- end of inner cons -->
</template>

<script>
export default {
  name: "MarketMyProperties",
  metaInfo: {
    title: "Market My Properties"
  },
  data() {
    return {
      form: {
        transactionType: [],
        propertyType: null,
        region: null,
        city: null,
        postcode: null,
        rentalPrice: null,
        rentalType: "month",
        salePrice: null,
        landSize: null,
        buildingSize: null,
        totalBedRoom: null,
        totalBathRoom: null,
        totalCarLot: null,
        waterSource: "",
        electricSource: null,
        wattage: null,
        maintenanceFee: null,
        maintananceFeeType: "month",
        neighborhoodFee: null,
        neighbourhoodFeeType: "month",
        description: ""
      },
      propertyType: [],
      regions: [],
      cities: [],
      showRentalPriceType: false,
      showMaintenanceFeeType: false,
      showNeighbourhoodFeeType: false
    };
  },
  mounted() {
    this.getPropertyType();
    this.getRegions();
  },
  methods: {
    getPropertyType() {
      this.$axios
        .get(`/misc/property-types`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.propertyType = response.data.data;
        });
    },
    getRegions() {
      this.$axios
        .get(`/misc/provinces`, {
          params: {
            province: "all"
          }
        })
        .then(response => {
          this.regions = response.data.data;
        });
    },
    getCities() {
      this.form.city = null;
      this.$axios.get(`/misc/cities/${this.form.region.id}`).then(response => {
        this.cities = response.data.data;
      });
    },
    formatRupiah(form) {
      if (this.form[form] !== null) {
        const angka = this.convertToAngka(this.form[form]);
        const rupiah = this.convertToRupiah(angka, false);
        this.form[form] = rupiah;
      }
    },
    submitForm() {
      this.$axios
        .post(`/market-my-properties`, {
          transactionType: this.form.transactionType,
          propertyTypeId: this.form.propertyType,
          region: this.form.region.name,
          city: this.form.city,
          zip: this.form.postcode,
          address: this.form.address,
          rentalType: this.form.rentalType,
          rentalRate:
            this.form.rentalPrice !== null && this.form.rentalPrice !== ""
              ? this.convertToAngka(this.form.rentalPrice)
              : null,
          salesRate:
            this.form.salePrice !== null && this.form.salePrice !== ""
              ? this.convertToAngka(this.form.salePrice)
              : null,
          landSize:
            this.form.landSize !== null && this.form.landSize !== ""
              ? this.form.landSize
              : null,
          buildingSize:
            this.form.buildingSize !== null && this.form.buildingSize !== ""
              ? this.form.buildingSize
              : null,
          numBedroom:
            this.form.totalBedRoom !== null && this.form.totalBedRoom !== ""
              ? this.form.totalBedRoom
              : "",
          numBathroom:
            this.form.totalBathRoom !== null && this.form.totalBathRoom !== ""
              ? this.form.totalBathRoom
              : "",
          numCarLot:
            this.form.totalCarLot !== null && this.form.totalCarLot !== ""
              ? this.form.totalCarLot
              : "",
          waterSource: this.form.waterSource,
          electricitySource: this.form.electricitySource,
          wattage: this.form.wattage,
          maintananceFee: this.form.maintenanceFee,
          neighbourhoodFee: this.form.neighborhoodFee,
          description: this.form.description
        })
        .then(response => {
          this.$swal({
            icon: "success",
            text: response.data.message
          });
          window.location.href = "https://wa.me/+6287708779768";
          // this.$router.push(
          //   `/account/message?conversation=${response.data.data.id}`
          // );
        })
        .catch(error => {
          this.$swal({
            icon: "error",
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
